<template>
  <div class="home">
    <Banner-div></Banner-div>
    <Samrt-ele></Samrt-ele>
    <Factors></Factors>
    <Connect></Connect>
    <FooterBox></FooterBox>
  </div>
</template>

<script>
// @ is an alias to /src
import BannerDiv from "@/components/home/Banner";
import SamrtEle from "@/components/home/SamrtEle";
import Factors from "@/components/home/Factors";
import Connect from "@/components/home/Connect";
import FooterBox from "@/components/home/FooterBox";
export default {
  name: "Home",
  components: {
    BannerDiv,
    SamrtEle,
    Factors,
    Connect,
    FooterBox
  }
};
</script>
