<template>
  <div class="smart_lay">
    <div class="home_contanier">
      <div class="max60_font_css">Make Smarter Financial Decisions</div>
      <div class="title-desc">
        With credit scores and special anti-fraud blacklists, OScore helps
        lenders get an accurate yet deep insight of risk on the borrower, which
        improves overall effectiveness and profitability on the credit market.
      </div>
      <SmartEleItem
        v-for="item in itemData"
        :key="item.id"
        :imgType="item.imgType"
        :img="item.img"
        :title="item.title"
        :desc="item.desc"
        :id="item.id"
      ></SmartEleItem>
    </div>
  </div>
</template>

<script>
import SmartEleItem from "@/components/SmartEleItem";
export default {
  name: "SamrtEle",
  components: {
    SmartEleItem
  },
  data() {
    return {
      itemData: [
        {
          id: 1,
          imgType: "left",
          img: require("../../assets/images/smart1.svg"),
          title: "A precise view of credit of every user",
          desc:
            "OScore consolidates a users’ crypto addresses and accounts, including how they repay credit obligations. The scores are built based on data authorized by user and updated on-demand to reflect latest status in consumer's behavior and lending practices."
        },
        {
          id: 2,
          imgType: "right",
          img: require("../../assets/images/smart2.svg"),
          title: "A reference to grant credit and control risk",
          desc:
            "Credit grantors can better determine whether to raise a credit line and how much credit to extend. And a blacklist is available to check and action upon, for instance, consumers with serious delinquencies will appear with a mark."
        },
        {
          id: 3,
          imgType: "left",
          img: require("../../assets/images/smart3.svg"),
          title: "A targeted scoreboard for multiple segments",
          desc:
            "OScore is built based on multiple factors, it could be tuned to assess risk for a specific segment - DeFi, automobile, human resource, banking. "
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
.smart_lay {
  padding-top: 2rem;
  .max60_font_css {
    text-align: center;
  }
  .title-desc {
    font-size: 0.16rem;
    opacity: 0.6;
    line-height: 0.26rem;
    text-align: center;
    width: 100%;
    max-width: 10rem;
    margin: 0 auto;
    margin-top: 0.4rem;
    margin-bottom: 1.2rem;
  }
}

@media only screen and (max-width: 767px) {
  .smart_lay {
    padding-top: 4rem;
    .title-desc {
      font-size: 0.28rem;
      line-height: 0.46rem;
      margin-top: 0.6rem;
      margin-bottom: 2rem;
    }
  }
}
</style>
