<template>
  <div class="banner-ele--lay" ref="ont_trust_home">
    <ul class="banner-ele-ul">
      <li class="hidenone" ref="trust_list1">
        <img src="../../assets/images/ele1.svg" alt="" />
        <div class="item-title">Credit Loans</div>
      </li>
      <li class="hidenone" ref="trust_list2">
        <img src="../../assets/images/ele2.svg" alt="" />
        <div class="item-title">Loan Refinancing</div>
      </li>
      <li class="hidenone" ref="trust_list3">
        <img src="../../assets/images/ele3.svg" alt="" />
        <div class="item-title">Anti-Fraud & Blacklist</div>
      </li>
      <li class="hidenone" ref="trust_list4">
        <img src="../../assets/images/ele4.svg" alt="" />
        <div class="item-title">Asset and Identity Monitoring</div>
      </li>
    </ul>
  </div>
</template>

<script>
import "animate.css";
export default {
  name: "BannerEle",
  methods: {
    handleScrollFadeIn() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let targetTop = this.$refs.ont_trust_home.offsetTop; // 目标元素离页面顶端的距离
      if (this.locks) {
        return false;
      } else {
        if (scrollTop < targetTop) {
          this.$refs.trust_list1.classList.remove("hidenone");
          this.$refs.trust_list1.classList.add("normalcss");
          setTimeout(() => {
            this.$refs.trust_list2.classList.remove("hidenone");
            this.$refs.trust_list2.classList.add("normalcss");
          }, 200);
          setTimeout(() => {
            this.$refs.trust_list3.classList.remove("hidenone");
            this.$refs.trust_list3.classList.add("normalcss");
          }, 600);
          setTimeout(() => {
            this.$refs.trust_list4.classList.remove("hidenone");
            this.$refs.trust_list4.classList.add("normalcss");
          }, 800);
          this.locks = true;
        }
      }
    }
  },
  mounted() {
    this.handleScrollFadeIn();
    window.addEventListener("scroll", this.handleScrollFadeIn);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollFadeIn);
  }
};
</script>

<style lang="less" scoped>
.banner-ele--lay {
  width: 100%;
  max-width: 12.8rem;
  margin: 0 auto;
  ul.banner-ele-ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    li {
      width: 23%;
      min-height: 2rem;
      padding: 0.4rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      //cursor: pointer;
      opacity: 0;
      transition: box-shadow 0.4s, opacity 1s, transform 1s;
      transform: translateY(0.4rem);
      background: #fff;
      &:hover {
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
      }
      img {
        width: 0.72rem;
        height: 0.72rem;
      }
      .item-title {
        margin-top: 0.2rem;
        font-size: 0.18rem;
        font-weight: 500;
        line-height: 0.26rem;
        text-align: center;
      }
    }
    li.normalcss {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
.hidenone {
  visibility: hidden;
}
@media only screen and (max-width: 767px) {
  .banner-ele--lay {
    ul.banner-ele-ul {
      li {
        width: 100%;
        min-height: 4rem;
        padding: 0.8rem 0;
        img {
          width: 1.4rem;
          height: 1.4rem;
        }
        .item-title {
          margin-top: 0.4rem;
          font-size: 0.36rem;
          line-height: 0.52rem;
        }
      }
    }
  }
}
</style>
