<template>
  <div
    :class="
      imgType === 'right' ? 'smart-ele-item reverse-item' : 'smart-ele-item'
    "
    :ref="bindEle"
  >
    <img :src="img" alt="" />
    <div class="main-info">
      <div class="max40_font_css">{{ title }}</div>
      <div class="sub-title-desc">
        {{ desc }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    imgType: {
      type: String,
      default: "left"
    },
    img: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    desc: {
      type: String,
      default: ""
    }
  },
  computed: {
    bindEle() {
      return "smart-ele-" + this.id;
    }
  },
  name: "SmartEleItem",
  methods: {
    handleScrollFadeIn() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let targetTop = this.$refs[this.bindEle].offsetTop; // 目标元素离页面顶端的距离
      // console.log("targetTop", targetTop);
      if (this.locks) {
        return false;
      } else {
        if (scrollTop > targetTop - 800) {
          this.$refs[this.bindEle].classList.remove("hidenone");
          this.$refs[this.bindEle].classList.add("normalcss");
          this.locks = true;
        }
      }
    }
  },
  mounted() {
    // this.handleScrollFadeIn();
    window.addEventListener("scroll", this.handleScrollFadeIn);
    // console.log("this.$refs", this.$refs);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollFadeIn);
  }
};
</script>

<style lang="less" scoped>
.smart-ele-item {
  width: 100%;
  min-height: 5rem;
  background: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 1.2rem;
  margin-bottom: 0.8rem;
  opacity: 0;
  transition: box-shadow 0.4s, opacity 2s, transform 2s;
  transform: translateY(0.8rem);
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  img {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0 2rem;
  }
  .main-info {
    flex: 1;
    .sub-title-desc {
      margin-top: 0.3rem;
      font-size: 0.16rem;
      opacity: 0.6;
      line-height: 0.26rem;
    }
  }
}
.smart-ele-item.reverse-item {
  flex-direction: row-reverse;
  padding-right: 0;
  padding-left: 1.2rem;
}
.smart-ele-item.normalcss {
  transform: translateY(0);
  opacity: 1;
}
.hidenone {
  visibility: hidden;
}
@media only screen and (max-width: 767px) {
  .smart-ele-item {
    min-height: 10rem;
    height: auto;
    padding-right: 0rem;
    padding: 1rem 0.4rem;
    margin-bottom: 1.4rem;
    flex-wrap: wrap;
    flex-direction: column;
    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
    img {
      width: 2.2rem;
      height: 2.2rem;
      margin: 0;
      margin-bottom: 0.8rem;
    }
    .main-info {
      width: 100%;
      .sub-title-desc {
        margin-top: 0.6rem;
        font-size: 0.28rem;
        line-height: 0.44rem;
      }
    }
  }
  .smart-ele-item.reverse-item {
    flex-direction: column;
    padding: 1rem 0.4rem;
  }
}
</style>
