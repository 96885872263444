<template>
  <div class="connect-lay">
    <div class="home_contanier">
      <div class="max60_font_css">Connect With OScore</div>
      <ul class="connect-ul" ref="connect_ul">
        <li ref="fa_li_1" class="fa-li hidenone">
          <div class="color-bar yellow-bg-color"></div>
          <div class="li-ele">
            <div class="max28_font_css">Basic</div>
            <ul class="options-ul">
              <li class="options-ele">
                OScore for DeFi
              </li>
              <li class="options-ele">
                Real-time update
              </li>
              <li class="options-ele">
                SDK/API integration support
              </li>
              <li class="options-ele">
                Free Trial - not trail
              </li>
            </ul>
          </div>
          <div class="s-primary-btn-color btn-css" @click="openurl(basicurl)">
            View Docs
          </div>
        </li>
        <li ref="fa_li_2" class="fa-li hidenone">
          <div class="color-bar blue-bg-color"></div>
          <div class="li-ele">
            <div class="max28_font_css">Advanced</div>
            <ul class="options-ul">
              <li class="options-ele">
                OScore for DeFi
              </li>
              <li class="options-ele">
                Real-time update
              </li>
              <li class="options-ele">
                Delinquency mark
              </li>
              <li class="options-ele">
                Blacklist reference
              </li>
              <li class="options-ele">
                Additional credit insights
              </li>
              <li class="options-ele">
                SDK/API integration support
              </li>
            </ul>
          </div>
          <div class="s-primary-btn-color btn-css">
            <a href="mailto:contract@ont.io">Get in touch</a>
          </div>
        </li>
        <li ref="fa_li_3" class="fa-li hidenone">
          <div class="color-bar black-bg-color"></div>
          <div class="li-ele">
            <div class="max28_font_css">Premier</div>
            <ul class="options-ul">
              <li class="options-ele">
                OScore for DeFi
              </li>
              <li class="options-ele">
                OScore for mortgages, auto <br />
                loans & more
              </li>
              <li class="options-ele">
                Real-time update
              </li>
              <li class="options-ele">
                Delinquency report
              </li>
              <li class="options-ele">
                Blacklist reference
              </li>
              <li class="options-ele">
                Additional credit insights
              </li>
              <li class="options-ele">
                SDK/API integration support
              </li>
            </ul>
          </div>
          <div class="s-primary-btn-color btn-css no-action">Coming Soon</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Connect",
  data() {
    return {
      basicurl:
        "https://github.com/ont-bizsuite/documentation/blob/master/oscore/restapi.md"
    };
  },
  methods: {
    openurl(url, type = "_blank") {
      window.open(url, type);
    },
    handleScrollFadeIn() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let targetTop = this.$refs.connect_ul.offsetTop; // 目标元素离页面顶端的距离
      if (this.locks) {
        return false;
      } else {
        if (scrollTop > targetTop - 1000) {
          this.$refs.fa_li_1.classList.remove("hidenone");
          this.$refs.fa_li_1.classList.add("normalcss");
          setTimeout(() => {
            this.$refs.fa_li_2.classList.remove("hidenone");
            this.$refs.fa_li_2.classList.add("normalcss");
          }, 200);
          setTimeout(() => {
            this.$refs.fa_li_3.classList.remove("hidenone");
            this.$refs.fa_li_3.classList.add("normalcss");
          }, 600);
          this.locks = true;
        }
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollFadeIn);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollFadeIn);
  }
};
</script>

<style lang="less" scoped>
.connect-lay {
  padding: 1.2rem 0;
  background: #0cbf92;
  .max60_font_css {
    color: #fff;
    margin-bottom: 1rem;
  }
  ul.connect-ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    li.fa-li {
      width: 3.6rem;
      min-height: 5.4rem;
      background: #fff;
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      padding-bottom: 0.4rem;
      overflow: hidden;
      opacity: 0;
      transition: box-shadow 0.4s, opacity 1s, transform 1s;
      transform: translateY(0.4rem);
      &:hover {
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.3);
      }
      .color-bar {
        width: 100%;
        height: 0.16rem;
      }
      .li-ele {
        flex: 1;
        padding: 0.4rem;
        width: 100%;
      }
      .btn-css {
        font-size: 0.14rem;
        a {
          display: block;
          color: #fff;
        }
      }
      .btn-css.no-action {
        background: #f2f2f2;
        color: #c4c4c4;
        cursor: not-allowed;
      }
    }
    li.fa-li.normalcss {
      transform: translateY(0);
      opacity: 1;
    }
  }
  ul.options-ul {
    margin-top: 0.4rem;
    li {
      font-size: 0.14rem;
      color: rgba(0, 0, 0, 0.6);
      padding-left: 0.36rem;
      line-height: 0.2rem;
      margin-bottom: 0.15rem;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.04rem;
        width: 0.16rem;
        height: 0.12rem;
        background: url("../../assets/images/right-icon.svg") no-repeat;
        background-size: contain;
      }
    }
  }
}
.hidenone {
  visibility: hidden;
}
.yellow-bg-color {
  background: #ffc800;
}
.blue-bg-color {
  background: #2f8ad0;
}
.black-bg-color {
  background: #000;
}
@media only screen and (max-width: 767px) {
  .connect-lay {
    padding: 2.4rem 0;
    .max60_font_css {
      margin-bottom: 1.6rem;
    }
    ul.connect-ul {
      li.fa-li {
        width: 5.9rem;
        min-height: 10.8rem;
        padding-bottom: 0.8rem;
        margin-bottom: 0.8rem;
        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
        .color-bar {
          height: 0.32rem;
        }
        .li-ele {
          flex: 1;
          padding: 0.8rem;
        }
        .btn-css {
          font-size: 0.28rem;
        }
      }
    }
    ul.options-ul {
      margin-top: 0.8rem;
      li {
        font-size: 0.28rem;
        padding-left: 0.72rem;
        line-height: 0.4rem;
        margin-bottom: 0.3rem;
        &::before {
          top: 0.08rem;
          width: 0.32rem;
          height: 0.24rem;
        }
      }
    }
  }
}
</style>
