<template>
  <div class="banner_lay">
    <div class="top-bar-area">
      <img src="../../assets/images/logo.svg" class="home-logo" alt="" />
      <div class="s-primary-btn-color" @click="openurl(loginUrl, '_self')">
        Sign in
      </div>
    </div>
    <div class="home_contanier">
      <div class="min-center-box">
        <div class="max80_font_css">
          The First <br />
          Credit Score on Blockchain
        </div>
        <div class="max24_font_m_css">
          Open, Trusted and Innovative
        </div>
        <div class="btn-arr-lay">
          <div class="m-primary-btn-color" @click="openurl(githuburl)">
            Learn more
          </div>
          <div class="m-primary-btn-color hollow-out">
            <a href="mailto:contract@ont.io">Request demo</a>
          </div>
        </div>
      </div>
      <img src="../../assets/images/bannerbg.svg" alt="" />
    </div>
    <Banner-ele></Banner-ele>
  </div>
</template>

<script>
import BannerEle from "@/components/home/BannerEle";
export default {
  components: { BannerEle },
  data() {
    return {
      loginUrl: "https://ocredit.io/dashboard",
      githuburl:
        "https://github.com/ont-bizsuite/documentation/tree/master/oscore"
    };
  },
  methods: {
    openurl(url, type = "_blank") {
      window.open(url, type);
    }
  }
};
</script>

<style lang="less" scoped>
.banner_lay {
  padding-top: 2.1rem;
  position: relative;
  .home_contanier {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 6rem;
    margin-bottom: 1.3rem;
    .min-center-box {
      position: relative;
      z-index: 3;
      width: 100%;
      //max-width: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .max24_font_m_css {
        margin-top: 0.2rem;
        font-weight: 500;
      }
      .btn-arr-lay {
        margin-top: 0.6rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .m-primary-btn-color {
        transition: box-shadow 0.5s;
        &:nth-of-type(1) {
          margin-right: 0.2rem;
        }

        &:hover {
          box-shadow: 0px 10px 20px 0px rgba(12, 191, 146, 0.3);
        }
        &.hollow-out {
          transition: background 0.8s;
          a {
            color: #0cbf92;
            display: block;
            transition: color 0.2s;
          }
          &:hover {
            background: #0cbf92;
            a {
              color: #fff;
            }
          }
        }
      }
    }
    img {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }
  }
  .top-bar-area {
    position: absolute;
    width: 100%;
    max-width: 12.8rem;
    left: 50%;
    top: 0.5rem;
    transform: translateX(-50%);
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.14rem;
    img.home-logo {
      width: 1.5rem;
      height: 0.26rem;
    }
  }
}
.max80_font_css {
  text-align: center;
}
.max24_font_m_css {
  opacity: 0.3;
  text-align: center;
}
@media only screen and (min-width: 768px) and(max-height: 1000px) {
  .banner_lay {
    padding-top: 1.4rem;
    .home_contanier {
      height: 5.4rem;
      margin-bottom: 0.6rem;
      .min-center-box {
        .max80_font_css {
          font-size: 0.64rem;
          line-height: 0.72rem;
        }
        .max24_font_m_css {
          margin-top: 0.2rem;
          font-size: 0.18rem;
        }
        .btn-arr-lay {
          margin-top: 0.6rem;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and(max-height: 720px) {
  .banner_lay {
    padding-top: 1rem;
    .home_contanier {
      height: 4.8rem;
      margin-bottom: 0.4rem;
      .min-center-box {
        .max80_font_css {
          font-size: 0.58rem;
          line-height: 0.64rem;
        }
        .max24_font_m_css {
          margin-top: 0.2rem;
          font-size: 0.2rem;
        }
        .btn-arr-lay {
          margin-top: 0.4rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .banner_lay {
    padding-top: 1.6rem;
    .home_contanier {
      height: 12rem;
      margin-bottom: 0.8rem;
      .min-center-box {
        .max24_font_m_css {
          margin-top: 0.2rem;
        }
        .btn-arr-lay {
          margin-top: 0.6rem;
          flex-direction: column;
          .m-primary-btn-color {
            min-width: 2.7rem;
            &:nth-of-type(1) {
              margin-right: 0;
              margin-bottom: 0.4rem;
            }
          }
        }
      }
      img {
        height: 50%;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        z-index: 2;
      }
    }
    .top-bar-area {
      top: 1.6rem;
      font-size: 0.28rem;
      padding: 0 0.4rem;
      justify-content: center;
      img.home-logo {
        width: 2.8rem;
        height: 0.48rem;
      }
    }
  }
  .s-primary-btn-color {
    display: none;
  }
}
</style>
