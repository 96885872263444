<template>
  <div class="factors-lay">
    <div class="home_contanier">
      <div class="max60_font_css">OScore factors</div>
      <ul class="factor-ul hidenone" ref="factor_ul">
        <li>
          <div class="sort max24_font_s_css">01</div>
          <div class="options-item max40_font_m_css">
            Crypto currency holdings
          </div>
        </li>
        <li>
          <div class="sort max24_font_s_css">02</div>
          <div class="options-item max40_font_m_css">
            Crypto exchange account
          </div>
        </li>
        <li>
          <div class="sort max24_font_s_css">03</div>
          <div class="options-item max40_font_m_css">
            KYC <br class="mbnone" />
            status
          </div>
        </li>
        <li>
          <div class="sort max24_font_s_css">04</div>
          <div class="options-item max40_font_m_css">
            DeFi 2.0 delinquencies
          </div>
        </li>
        <li>
          <div class="sort max24_font_s_css">05</div>
          <div class="options-item max40_font_m_css">
            Traditional credit info
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Factors",
  data() {
    return {
      listData: [
        {
          id: "01",
          words: "Crypto currency holdings"
        },
        {
          id: "02",
          words: "Crypto exchange account"
        },
        {
          id: "03",
          words: "KYC \n status"
        },
        {
          id: "04",
          words: "Crypto currency holdings"
        },
        {
          id: "05",
          words: "Crypto currency holdings"
        }
      ]
    };
  },
  methods: {
    handleScrollFadeIn() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let targetTop = this.$refs.factor_ul.offsetTop; // 目标元素离页面顶端的距离
      if (this.locks) {
        return false;
      } else {
        if (scrollTop > targetTop - 800) {
          this.$refs.factor_ul.classList.remove("hidenone");
          this.$refs.factor_ul.classList.add("normalcss");
          this.locks = true;
        }
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollFadeIn);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollFadeIn);
  }
};
</script>

<style lang="less" scoped>
.factors-lay {
  padding: 2.4rem 0 0.8rem;
  .max60_font_css {
    margin-bottom: 0.8rem;
  }
  ul.factor-ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    opacity: 0;
    transition: opacity 0.6s, transform 1s;
    transform: translateY(0.4rem);
    li {
      width: 3rem;
      margin-right: 1.2rem;
      margin-bottom: 0.8rem;
      .sort {
        font-weight: 400;
        margin-bottom: 0.1rem;
        opacity: 0.3;
      }
      .options-item {
        margin-bottom: 0.08rem;
        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
      }
    }
  }
  ul.factor-ul.normalcss {
    transform: translateY(0);
    opacity: 1;
  }
}
.hidenone {
  visibility: hidden;
}
@media only screen and (max-width: 767px) {
  .factors-lay {
    padding: 4rem 0 1.6rem;
    .max60_font_css {
      margin-bottom: 1.6rem;
    }
    ul.factor-ul {
      li {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 1rem;
        .sort {
          margin-bottom: 0.2rem;
        }
        .options-item {
          margin-bottom: 0.14rem;
          max-width: 6rem;
          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .mbnone {
    display: none;
  }
}
</style>
