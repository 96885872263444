<template>
  <div class="footer-lay">
    <div class="home_contanier footer-slider">
      <img class="footer-logo" src="../../assets/images/logo.svg" alt="" />
      <ul class="footer-link">
        <li>
          <div class="link-title">Community</div>
          <div class="link-ele" @click="openurl(Telegram)">Telegram</div>
        </li>
        <li>
          <div class="link-title">Support</div>
          <div class="link-ele" @click="openurl(githubDoc)">Documentation</div>
          <div class="link-ele" @click="openurl(github)">Github</div>
        </li>
        <li>
          <div class="link-title">Contact Us</div>
          <div class="link-ele">
            <a href="mailto:contract@ont.io">Contact@ont.io</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="copyright">© 2020 Ontology — All Rights Reserved</div>
  </div>
</template>

<script>
export default {
  name: "FooterBox",
  data() {
    return {
      github:
        "https://github.com/ont-bizsuite/documentation/tree/master/oscore",
      githubDoc:
        "https://github.com/ont-bizsuite/documentation/blob/master/oscore/restapi.md",
      Telegram: "https://t.me/OntologyAnnouncements"
    };
  },
  methods: {
    openurl(url, type = "_blank") {
      window.open(url, type);
    }
  }
};
</script>

<style lang="less" scoped>
.footer-lay {
  padding-top: 1.6rem;
  .footer-slider {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 0.16rem;
    padding-bottom: 0.8rem;
    img {
      width: 1.5rem;
      height: 0.26rem;
    }
    .footer-link {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      li {
        margin-right: 1.2rem;
        &:nth-last-of-type(1) {
          margin-right: 0;
        }
        .link-title {
          font-size: 0.16rem;
          font-weight: 500;
          line-height: 0.2rem;
          margin-bottom: 0.24rem;
        }
        .link-ele {
          font-size: 0.14rem;
          line-height: 0.18rem;
          opacity: 0.6;
          margin-bottom: 0.2rem;
          cursor: pointer;
          transition: opacity 0.6s;
          &:hover {
            opacity: 0.8;
          }
          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .copyright {
    border-top: 1px solid #f2f2f2;
    padding: 0.3rem;
    font-size: 0.14rem;
    color: rgba(0, 0, 0, 0.3);
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .footer-lay {
    padding-top: 2.7rem;
    .footer-slider {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      padding-bottom: 0.6rem;
      img {
        width: 3rem;
        height: 0.52rem;
        margin-bottom: 0.78rem;
      }
      .footer-link {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-left: 0.1rem;
        li {
          width: 100%;
          margin-right: 0rem;
          margin-bottom: 0.6rem;
          .link-title {
            font-size: 0.32rem;
            line-height: 0.46rem;
            margin-bottom: 0.2rem;
          }
          .link-ele {
            font-size: 0.28rem;
            line-height: 0.4rem;
            margin-bottom: 0.4rem;
          }
          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }
      }
    }
    .copyright {
      padding: 0.6rem;
      font-size: 0.28rem;
      border-top: none;
    }
  }
}
</style>
